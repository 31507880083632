@use 'src/src/app/scss/abstracts' as *;

.highlight-card {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  text-decoration: none;

  &__media {
  }

  &__media-inner {
    overflow: hidden;
    border-radius: $border-radius-s;

    @include mq($until: sm) {
      border-radius: $border-radius-2xs;
    }
  }

  &__title {
    color: $color-base-purple-200;
    padding-left: 80px;
    padding-top: 15px;

    @include mq($until: md) {
      padding-left: 54px;
    }
  }

  &--loading {
    @include skeleton;

    border-radius: $border-radius-s;
    overflow: hidden;
    pointer-events: none;

    @include mq($until: sm) {
      border-radius: $border-radius-2xs;
    }

    #{$block-name} {
      &__media {
        opacity: 0;
      }

      &__title {
        opacity: 0;
      }
    }
  }
}
